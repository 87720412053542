import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import imgCreditCards from '../images/credit_cards.png'
import imgHeaderImage from '../images/top_membership.jpg'

const IndexPage = () => {

  return (
    <Layout pageTitle="Dental Membership" headerImage={imgHeaderImage}>
      <SEO title="Dental Membership"/>
      <div>
        <h2 className="section-title">Dental Membership</h2>
        <p className="general-text">We are pleased to offer Pipers Dental Membership, incorporating our own payment scheme to provide you with high quality dental care at an affordable price. The monthly fee is based on the number of routine examinations, hygiene appointments and x-rays you are likely to require in a year. The scheme allows you to budget for regular and preventive care.</p>
      </div>
      <div className="new-row">
        <div className="two-col-1">
          <h3 className="info-title">Benefits include:</h3>
          <ul>
            <li>Regular dental examinations and gum assessments</li>
            <li>Professional hygiene care</li>
            <li>Discounts on most dental treatment</li>
            <li>Worldwide Dental Trauma and Emergency Callout Insurance</li>
            <li>All treatment planning for your future dental needs</li>
            <li>Redundancy protection for your monthly payments for up to 12 months</li>
          </ul>

          <p className="general-text">To find out more about our payment plan telephone us on 01883 712486 or call in.</p>
        </div>
        <div className="two-col-2">
          <h3 className="info-title">Prices and Payments</h3>
          <p className="general-text">Quotations are given before any planned treatment is started and we accept payment by cheque, cash or credit cards</p>
          <br/>
          <img src={imgCreditCards} alt="Credit Cards" title="Credit Cards"/>
          <br/>
          <br/>
          <h3 className="info-title">Price List</h3>
          <p className="general-text">Please <a className="standard-link" href="/info/feeguide">click here</a> to view prices for the various dental treatments on offer.</p>
        </div>
      </div>
    </Layout>
  )
}



export default IndexPage
